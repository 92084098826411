import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import styled from "styled-components"
import Div8095 from "../components/utils/Div8095"
import HeadingCard from "../components/utils/HeadingCard"
import ContainerDiv from "../components/utils/ContainerDiv"
import ContactCard from "../components/utils/ContactCard"
const StyledGrid = styled.div`
  margin-top: 1em;
  padding: 1em;
  background-color: white;
  display: grid;
  grid-template-columns: 30% 1fr;
  grid-tempalte-rows: 1fr 1fr 1fr;
  grid-gap: 1em;
  align-items: start;
  @media (max-width: 750px) {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
  }
`
const StyledDesc = styled.div`
  grid-column: 2 / span 1;
  grid-row: 1 / span 3;
  @media (max-width: 750px) {
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
  }
`
const StyledPic = styled.div`
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  @media (max-width: 750px) {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
  }
`
const Mentor = ({ pageContext }) => (
  <Layout>
    <SEO title={pageContext.fullname} />
    <ContainerDiv bg="#9abbcc">
      <Div8095>
        <HeadingCard w="10em">{pageContext.fullname}</HeadingCard>
        <StyledGrid>
          <StyledPic>
            <img
              src={require(`../images/mentors/${pageContext.image}`)}
              alt={pageContext.fullname}
            />
          </StyledPic>

          <StyledDesc dangerouslySetInnerHTML={{ __html: pageContext.desc }} />
          <ContactCard
            name={pageContext.fullname}
            contactArray={Object.values(pageContext.contactInfo)}
          />
        </StyledGrid>
      </Div8095>
    </ContainerDiv>
  </Layout>
)

export default Mentor
