import React from "react"
import styled from "styled-components"
import insta from "../../images/icons/instagram.svg"
import linkedin from "../../images/icons/linkedin.svg"
import twitter from "../../images/icons/twitter.svg"
import website from "../../images/icons/website.svg"
const StyledDiv = styled.div`
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  @media (max-width: 750px) {
    padding-top: 1em;
    grid-column: 1 / span 1;
    grid-row: 3 / span 1;
  }
`
const StyledAnchor = styled.a`
  padding-left: 1em;
  height: 1.3em;
`
const StyledImg = styled.img`
  width: 1.2em;
`
const ContactCard = ({ name, contactArray }) => {
  const icons = [insta, linkedin, twitter, website]
  const contactList = contactArray.map((e, k) => {
    return e !== "" ? (
      <StyledAnchor href={e} key={k} target="_blank" rel="noopener">
        <StyledImg src={icons[k]} alt={e} />
      </StyledAnchor>
    ) : (
      ""
    )
  })
  return (
    <StyledDiv>
      <span>Find {name} on:</span>
      {contactList}
    </StyledDiv>
  )
}

export default ContactCard
